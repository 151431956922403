.dot-navigation {
  transform: translateX(0px);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  display: none;
}

.dot-navigation-link {
  &:hover {
      text-decoration: none;
      border: 0;

      .dot-navigation-label {
          opacity: 1;
          pointer-events: all;
          transform: translateX(0px);
      }

      .dot-navigation-dot {
          border-width: 12px;
          border-collapse: #fff;
      }
  }
}

.dot-navigation .current .dot-navigation-dot {
  border-width: 12px;
}

.dot-navigation-label {
  text-shadow: 0 0 1px 2px black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out, transform 0.2s ease-in-out;
  transform: translateX(rem(20px));
}

.dot-navigation-dot {
  transition: border 0.1s ease-in-out;
  border-color: theme("colors.gray.900");
  border-width: 3px;
}

.overlay-open {
  .dot-navigation {
      transform: translateX(-20px);
  }

  .dot-navigation-dot {
      border-color: theme("colors.gray.100");
  }
  .dot-navigation-label {
      color: #fff;
      opacity: 1;
      pointer-events: all;
      transform: translateX(0px);
  }
}
