@import "~sass-rem/rem";

/*! purgecss start ignore */
@tailwind  base;

@font-face {
  font-family: Axis Extrabold;
  src: url(fonts/axis_extrabold.otf) format("opentype");
}

@import "base/layout.scss";
@import "base/article.scss";
@import "base/form.scss";
@import "components/dot-navigation.scss";
@import "components/button.scss";

@tailwind  components;
@import "components/button.scss";
/*! purgecss end ignore */

@tailwind utilities;