/*! purgecss start ignore */
@tailwind base;
@font-face {
  font-family: Axis Extrabold;
  src: url(fonts/axis_extrabold.otf) format("opentype"); }

/* ========================================================================
   Base
 ========================================================================== */
html {
  font-size: theme("fontSize.base");
  font-family: theme("fontFamily.body"); }

/* ========================================================================
   Article
 ========================================================================== */
.article {
  /*
    h1,
    h2 {
        font-size: theme("fontSize.3xl");
    }
    h3 {
        font-size: theme("fontSize.2xl");
    }
    h4 {
        font-size: theme("fontSize.xl");
    }
    h5 {
        font-size: theme("fontSize.lg");
    }
    h6 {
        font-size: theme("fontSize.base");
    }

    p {
    }
    */
  /* ========================================================================
    Lists
  ========================================================================== */
  /* ========================================================================
    Media
  ========================================================================== */
  /* ========================================================================
    Table
  ========================================================================== */ }
  .article p,
  .article ul,
  .article ol,
  .article dl,
  .article pre,
  .article address,
  .article fieldset,
  .article figure,
  .article blockquote {
    margin: 0 0 1rem 0; }
  .article h1,
  .article h2 {
    font-family: "Axis Extrabold"; }
  .article b,
  .article strong {
    font-weight: theme("fontWeight.bold"); }
  .article a {
    text-decoration: none; }
  .article hr {
    margin: theme("margin.10") 0; }
  .article blockquote {
    padding: theme("padding.4") theme("padding.6");
    background-color: theme("colors.neutral.100");
    border-left: 0.25rem theme("colors.neutral.400") solid;
    font-size: theme("fontSize.lg"); }
  .article blockquote > *:last-child {
    margin-bottom: 0; }
  .article cite {
    display: block;
    margin-top: 0.5rem;
    font-style: italic;
    text-align: right;
    font-size: theme("fontSize.base"); }
  .article pre {
    display: block;
    line-height: theme("lineHeight.relaxed");
    overflow-x: auto;
    border-radius: 4px;
    font-family: theme("fontFamily.mono");
    padding: theme("padding.4") theme("padding.6");
    word-break: break-all;
    word-wrap: break-word;
    color: theme("colors.neutral.800");
    background-color: theme("colors.neutral.100");
    border: 1px solid theme("colors.neutral.200"); }
  .article ul,
  .article ol {
    list-style-position: outside;
    margin: 0.75em 0;
    margin-left: 1.25rem; }
  .article ul {
    list-style: disc; }
  .article ol {
    list-style: decimal; }
  .article li {
    margin-bottom: 0.25em; }
  .article dt {
    font-size: theme("fontSize.sm");
    font-weight: normal;
    color: theme("colors.neutral.800");
    margin-bottom: 0.25rem; }
  .article dt:nth-child(n + 2) {
    margin-top: 1rem; }
  .article dd {
    margin-left: 0.5rem; }
  .article img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto; }
  .article svg {
    width: 100%;
    height: auto;
    fill: currentColor; }
  .article iframe,
  .article video,
  .article embed {
    display: block;
    max-width: 100%; }
  .article figcaption {
    text-align: right;
    padding: theme("padding.4") theme("padding.6"); }
  .article table {
    width: 100%;
    margin-bottom: 0;
    border-collapse: collapse;
    border-spacing: 0; }
  .article caption {
    margin: theme("margin.4") 0 theme("margin.2"); }
  .article tr:nth-of-type(even) {
    background: theme("colors.neutral.100");
    border-top: 1px solid theme("colors.neutral.200");
    border-bottom: 1px solid theme("colors.neutral.200"); }
  .article th,
  .article td {
    padding: theme("padding.4") theme("padding.6"); }
  .article th, .article tfoot td {
    text-align: left;
    vertical-align: bottom;
    font-size: theme("fontSize.sm");
    font-weight: normal;
    color: theme("colors.neutral.800"); }
  .article td {
    vertical-align: top; }

/* ========================================================================
   Form
 ========================================================================== */
.form-group {
  margin: 0 0 1rem 0; }

.input-group {
  position: relative; }

.input-group-append,
.input-group-prepend {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.input-group-prepend {
  left: 0; }

.input-group-prepend + input {
  padding-left: 2.5rem; }

.input-group-append {
  right: 0; }

.input-group input:first-child:not(:last-child) {
  padding-right: 2.5rem; }

.input-group-text {
  color: theme("colors.neutral.800"); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline > select + select {
    margin-left: theme("margin.3"); }

/* ========================================================================
   Label / Headings / Text
 ========================================================================== */
label,
legend {
  display: inline-block;
  margin-bottom: 0.5rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

button,
select {
  text-transform: none; }

.datenschutz a {
  text-decoration: underline; }

/* ========================================================================
   Input
 ========================================================================== */
.form-control {
  width: 100%;
  padding: 1rem;
  background: #fff;
  border: solid 1px #D5D5D5;
  outline: 0;
  appearance: none;
  transition: 0.2s ease-in-out;
  transition-property: background-color; }
  .form-control::placeholder {
    color: theme("colors.neutral.700"); }
  .form-control:focus {
    background: theme("colors.neutral.300"); }

textarea {
  overflow: auto;
  resize: vertical; }

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: .75rem;
  padding: 0;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out; }

input[type="range"]:hover {
  opacity: 1; }

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: theme("colors.primary.500");
  cursor: pointer; }

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  background: theme("colors.primary.500");
  cursor: pointer; }

/* ========================================================================
   Select
 ========================================================================== */
select:not([multiple]):not([size]) {
  max-width: 100%;
  padding-right: 2.5rem;
  font-weight: 500;
  background-image: url("data:image/svg+xml,%3Csvg style='fill:%23b9b9b9'%0Axmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='284.929px' height='284.929px' viewBox='0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 1em; }
  select:not([multiple]):not([size]):focus {
    background-image: url("data:image/svg+xml,%3Csvg style='fill:%23868686'%0Axmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='284.929px' height='284.929px' viewBox='0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/%3E%3C/svg%3E%0A"); }

select[multiple] {
  overflow: auto;
  padding: 0; }
  select[multiple] option {
    padding: 0.5rem 0.75rem;
    margin: 0; }

/* ========================================================================
   Radio / Checkbox
 ========================================================================== */
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }

.form-check-label {
  margin-bottom: 0; }

.form-check-input:disabled ~ .form-check-label {
  color: theme("colors.neutral.800"); }

.dot-navigation {
  transform: translateX(0px);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  display: none; }

.dot-navigation-link:hover {
  text-decoration: none;
  border: 0; }
  .dot-navigation-link:hover .dot-navigation-label {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0px); }
  .dot-navigation-link:hover .dot-navigation-dot {
    border-width: 12px;
    border-collapse: #fff; }

.dot-navigation .current .dot-navigation-dot {
  border-width: 12px; }

.dot-navigation-label {
  text-shadow: 0 0 1px 2px black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out, transform 0.2s ease-in-out;
  transform: translateX(1.25rem); }

.dot-navigation-dot {
  transition: border 0.1s ease-in-out;
  border-color: theme("colors.gray.900");
  border-width: 3px; }

.overlay-open .dot-navigation {
  transform: translateX(-20px); }

.overlay-open .dot-navigation-dot {
  border-color: theme("colors.gray.100"); }

.overlay-open .dot-navigation-label {
  color: #fff;
  opacity: 1;
  pointer-events: all;
  transform: translateX(0px); }

/* ========================================================================
   Button
 ========================================================================== */
.btn,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  display: inline-block;
  min-width: 6rem;
  padding: 1.5rem 3rem;
  margin-top: 0.3rem;
  font-family: inherit;
  font-size: theme("fontSize.base");
  color: #fff;
  text-decoration: none;
  background: theme("colors.primary.400");
  border: 0;
  /*border-radius: theme("borderRadius.default");*/
  cursor: pointer;
  appearance: none;
  transition: 0.3s ease-out; }
  .btn:hover,
  button:hover,
  input[type="button"]:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover {
    background: theme("colors.primary.600"); }
  .btn:focus,
  button:focus,
  input[type="button"]:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus {
    outline: none;
    box-shadow: theme("boxShadow.sm"); }

@tailwind components;
/* ========================================================================
   Button
 ========================================================================== */
.btn,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  display: inline-block;
  min-width: 6rem;
  padding: 1.5rem 3rem;
  margin-top: 0.3rem;
  font-family: inherit;
  font-size: theme("fontSize.base");
  color: #fff;
  text-decoration: none;
  background: theme("colors.primary.400");
  border: 0;
  /*border-radius: theme("borderRadius.default");*/
  cursor: pointer;
  appearance: none;
  transition: 0.3s ease-out; }
  .btn:hover,
  button:hover,
  input[type="button"]:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover {
    background: theme("colors.primary.600"); }
  .btn:focus,
  button:focus,
  input[type="button"]:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus {
    outline: none;
    box-shadow: theme("boxShadow.sm"); }

/*! purgecss end ignore */
@tailwind utilities;
