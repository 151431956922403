
/* ========================================================================
   Button
 ========================================================================== */

 .btn,
 button,
 input[type="button"],
 input[type="submit"],
 input[type="reset"] {
   display: inline-block;
   min-width: 6rem;
   padding: 1.5rem 3rem;
   margin-top: 0.3rem;
 
   font-family: inherit;
   font-size: theme("fontSize.base");
   color: #fff;
   text-decoration: none;
 
   background: theme("colors.primary.400");
 
   border: 0;
   /*border-radius: theme("borderRadius.default");*/
 
   cursor: pointer;
   appearance: none;
   transition: 0.3s ease-out;
 
   &:hover {
     background: theme("colors.primary.600");
   }
 
   &:focus {
     outline: none;
     box-shadow: theme("boxShadow.sm");
   }
 }