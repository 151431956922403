/* ========================================================================
   Article
 ========================================================================== */

.article {
    p,
    ul,
    ol,
    dl,
    pre,
    address,
    fieldset,
    figure,
    blockquote {
        margin: 0 0 1rem 0;
    }

    h1,
    h2 {
        font-family: "Axis Extrabold";
    }

/*
    h1,
    h2 {
        font-size: theme("fontSize.3xl");
    }
    h3 {
        font-size: theme("fontSize.2xl");
    }
    h4 {
        font-size: theme("fontSize.xl");
    }
    h5 {
        font-size: theme("fontSize.lg");
    }
    h6 {
        font-size: theme("fontSize.base");
    }

    p {
    }
    */

    b,
    strong {
        font-weight: theme("fontWeight.bold");
    }

    a {
        text-decoration: none;
    }

    hr {
        margin: theme("margin.10") 0;
    }

    address {
    }

    blockquote {
        padding: theme("padding.4") theme("padding.6");
        background-color: theme("colors.neutral.100");
        border-left: 0.25rem theme("colors.neutral.400") solid;
        font-size: theme("fontSize.lg");
    }

    blockquote > *:last-child {
        margin-bottom: 0;
    }

    cite {
        display: block;
        margin-top: 0.5rem;
        font-style: italic;
        text-align: right;
        font-size: theme("fontSize.base");
    }

    pre {
        display: block;
        line-height: theme("lineHeight.relaxed");
        overflow-x: auto;
        border-radius: 4px;
        font-family: theme("fontFamily.mono");
        padding: theme("padding.4") theme("padding.6");
        word-break: break-all;
        word-wrap: break-word;
        color: theme("colors.neutral.800");
        background-color: theme("colors.neutral.100");
        border: 1px solid theme("colors.neutral.200");
    }

    /* ========================================================================
    Lists
  ========================================================================== */

    ul,
    ol {
        list-style-position: outside;
        margin: 0.75em 0;
        margin-left: 1.25rem;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    li {
        margin-bottom: 0.25em;
    }

    dt {
        font-size: theme("fontSize.sm");
        font-weight: normal;
        color: theme("colors.neutral.800");
        margin-bottom: 0.25rem;
    }

    dt:nth-child(n + 2) {
        margin-top: 1rem;
    }

    dd {
        margin-left: 0.5rem;
    }

    /* ========================================================================
    Media
  ========================================================================== */

    img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    svg {
        width: 100%;
        height: auto;
        fill: currentColor;
    }

    iframe,
    video,
    embed {
        display: block;
        max-width: 100%;
    }

    figure {
    }

    figcaption {
        text-align: right;
        padding: theme("padding.4") theme("padding.6");
    }

    /* ========================================================================
    Table
  ========================================================================== */

    table {
        width: 100%;
        margin-bottom: 0;
        border-collapse: collapse;
        border-spacing: 0;
    }

    caption {
        margin: theme("margin.4") 0 theme("margin.2")
    }

    tr:nth-of-type(even) {
        background: theme("colors.neutral.100");
        border-top: 1px solid theme("colors.neutral.200");
        border-bottom: 1px solid theme("colors.neutral.200");
    }

    th,
    td {
        padding: theme("padding.4") theme("padding.6");
    }

    th, tfoot td {
        text-align: left;
        vertical-align: bottom;
        font-size: theme("fontSize.sm");
        font-weight: normal;
        color: theme("colors.neutral.800");
    }

    td {
        vertical-align: top;
    }
}
